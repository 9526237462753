import {message} from "antd"
let setLogin;

export function setSetLogin(func) {
  setLogin = func;
}

export default function handleServerResponse(response, setMessage) {
  switch(response) {
    case "pass_not_valid":
      // setMessage({ text: 'The password is not valid.', type: 'error' });
      message.error("The password is not valid.")
      break;
    case 'user_not_found':
      // setMessage({ text: 'User not found.', type: 'error' });
      message.error("User not found.")
      break;
    case 'username_already_use':
      //setMessage({ text: 'The username is already in use.', type: 'error' });
      message.error("The username is already in use.")
      break;
    case 'email_already_use':
      setMessage({ text: 'The email is already in use.', type: 'error' });
      message.error("The email is already in use.")
      break;
    case 'successful_register':
      // setMessage({ text: 'Registration successful!', type: 'success' });
      message.success("Registration successful!")
      setLogin && setLogin(true);
      break;
    case 'successful_login':
      // setMessage({ text: 'Login successful!', type: 'success' });
      message.success("Login successful!");
      window.location.reload();
      break;
    default:
      //setMessage({ text: `Unexpected error occurred. ${response}`, type: 'error' });
      message.error(`Unexpected error occurred.\n${response}`)
      break;
  }
}
