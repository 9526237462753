import handleServerResponse from '../utils/handleServerResponse.js';
const url = 'http://147.45.197.131:5000'
// const url = 'http://localhost:5000'

export async function tryVerify(token) {
  
  if(token === null || token === undefined){
    console.log(`Token is undefined`)

    return {
      user: undefined,
      success: false
    };
  }
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 
      'Authorization': token
    },
  };
  console.log(`TRYING TO VERIFY...` )

  const response = await fetch(`${url}/auth/verify_user/`, requestOptions);
  console.log(`${token} = ${response.status === 200}` )
  
  if(response.status === 200){
    const data = await response.json();
    const user = data?.user;
    return {
      user: user || undefined,
      success: response.status === 200
    };
  }
  else{
    return {
      user: undefined,
      success: response.status === 200
    };
  }

  
}

export async function tryLogin(values, setMessage) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  };
  const response = await fetch(`${url}/auth/login/`, requestOptions);

  console.log(response);
  const data = await response.json();

  if(data.token !== undefined)
    localStorage.setItem('token',data.token);

  handleServerResponse(data.message, setMessage);
}

export async function tryRegister(values, setMessage) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  };
  const response = await fetch(`${url}/auth/register/`, requestOptions);
  const data = await response.json();
  handleServerResponse(data.message, setMessage);
}
