import React, { useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import Errors from './Errors';
import { tryLogin, tryRegister } from '../services/authService';
import { setSetLogin } from '../utils/handleServerResponse';
import './App.css';

const onFinish = (values, isLogin, setMessage) => {
  // const token = Cookies.get('token');
  // console.log(`token is ${token}`);
  const updatedValues = { ...values, isLogin };

  if (isLogin) 
    tryLogin(updatedValues, setMessage);
  else 
    tryRegister(updatedValues, setMessage);
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const App = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState(null);

  setSetLogin(setIsLogin);

  return (
    <div>
      <h1>{isLogin ? 'Login' : 'Register'}</h1>
      <Errors message={message} onClose={() => setMessage(null)} />
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => onFinish(values, isLogin, setMessage)} 
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        {!isLogin && (
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input your email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        {!isLogin && (
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords do not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        )}

        {isLogin && (
          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button className='enter' type="primary" htmlType="submit">
            {isLogin ? 'Login' : 'Register'}
          </Button>
        </Form.Item>
      </Form>
      <div className="switch-buttons">
        <Button
          type="link"
          htmlType="button"
          onClick={() => {
            setIsLogin(!isLogin);
            setMessage(null);
          }}
        >
          {isLogin ? 'Switch to Register' : 'Switch to Login'}
        </Button>
      </div>
    </div>
  );
};

export default App;
