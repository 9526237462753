import React, { useState, useEffect } from 'react';
import Header from './Header';
import './Feed.css';
import { Card, Space, Input, Button, Spin } from 'antd';
import { getPosts, addPost } from '../services/postService.js';

const { TextArea } = Input;

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const data = await getPosts();
      setPosts(data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  const handlePost = async () => {
    const storedUser = JSON.parse(sessionStorage.getItem('user'));
    if (newPost.trim()) {
      const postData = { username: storedUser.username, content: newPost };
      const addedPost = await addPost(postData);
  
      if (addedPost) {
        window.location.reload();
      }
    }
  };

  return (
    <div>
      <Header />
      <div className='posts'>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <Card>
            <TextArea
              rows={4}
              autoSize={{ minRows: 4, maxRows: 4 }}
              value={newPost}
              onChange={(e) => setNewPost(e.target.value)}
              placeholder="Write a new post..."
            />
            <Button 
              type="primary" 
              onClick={handlePost} 
              style={{ marginTop: '8px' }} 
              disabled={!newPost.trim()}
            >
              Post
            </Button>
          </Card>
          {loading ? (
            <Spin tip="Loading..." />
          ) : (
            posts.slice().reverse().map((post, index) => (
              <Card key={index} title={post.username}>
                <p>{post.content}</p>
              </Card>
            ))
          )}
        </Space>
      </div>
    </div>
  );
};

export default Feed;
