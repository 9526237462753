import React from 'react';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  const goToAccount = () => {
    navigate('/account');
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start', // Кнопки будут выравниваться влево
      alignItems: 'center',
      padding: '10px',
      backgroundColor: '#f0f2f5',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 1000
    }}>
      <Button
        type="primary"
        icon={<HomeOutlined />}
        onClick={goToHome}
        style={{ marginRight: '10px' }}
      >
        Главная страница
      </Button>

      <Button
        type="primary"
        icon={<UserOutlined />}
        onClick={goToAccount}
      >
        Мой аккаунт
      </Button>
    </div>
  );
};

export default Header;
