// components/Errors.js
import React from 'react';
import { Alert } from 'antd';
import { CloseSquareFilled } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';

const Errors = ({ message, onClose }) => {
  return (
    <AnimatePresence>
      {message && (
        <motion.div
          key={message.timestamp} // Уникальный ключ для повторной анимации
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Alert
            message={message.text}
            type={message.type}
            closable
            onClose={onClose}
            icon={<CloseSquareFilled />}
            style={{ marginBottom: '16px' }}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Errors;
