import React from 'react';
import Header from './Header';
import { Button, message } from 'antd';
import './Account.css'; // Импортируем стили

const Account = () => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    // localStorage.setItem('token',localStorage.getItem('token') + "f");
    window.location.reload();
    message.success("Выход выполнен!");
  };

  const storedUser = JSON.parse(sessionStorage.getItem('user'));
  console.log(storedUser);

  return (
    <div>
      <Header />
      <div className="account-container">
        {storedUser && storedUser.username !== undefined && (
          <h2>Добро пожаловать, {storedUser.username}!</h2>
        )}
        
        <Button type="primary" danger onClick={handleLogout}>
          Выйти из аккаунта
        </Button>
      </div>
    </div>
    
  );
};

export default Account;
