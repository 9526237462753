const BASE_URL = 'http://147.45.197.131:5000/post'
// const BASE_URL = 'http://localhost:5000/post';

export const getPosts = async () => {
  try {

    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/get_posts`,{
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 
            'Authorization': token
          },
    });
    if (response.ok) {
      return await response.json();
    } else {
      console.error('Error fetching posts');
      return [];
    }
  } catch (error) {
    console.error('Error:', error);
    return [];
  }
};

export const addPost = async (postData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(`${BASE_URL}/add_post`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 
        'Authorization': token
      },
      body: JSON.stringify(postData),
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error('Error posting new post');
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
};
