import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { tryVerify } from './services/authService';
import Auth from './components/Auth';
import Feed from './components/Feed';
import Account from './components/Account';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    const verify = async () => {
      
      setTimeout(async () => {
        const response = (await tryVerify(token));
        setIsAuthenticated(response.success);
        sessionStorage.setItem('user', JSON.stringify(response.user));
      }, 0);
    };

    verify();
  }, []);

  if (isAuthenticated === undefined) {
    return <h1>Loading...</h1>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/"element={isAuthenticated ? <Navigate to="/feed" /> : <Auth />} />
        <Route path="/feed" element={<PrivateRoute element={<Feed />} isAuthenticated={isAuthenticated} />} />
        <Route path="/account" element={<PrivateRoute element={<Account/>} isAuthenticated={isAuthenticated} />} />
      </Routes>
    </Router>
  );
};

export default App;